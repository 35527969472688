import React from 'react';

// TODO: INTEGRATE GATSBY IMAGE

const GCMSpageImage = ({ imageNode: { url, altText } }) => {
	// console.log('>> GCMS page-image-display IMAGE NODE:', url, altText);

	return (
		<div className="page-image-display">
			<img src={url} alt={altText} />
		</div>
	);
};

export default GCMSpageImage;
