import React from 'react';
import { graphql } from 'gatsby';
// import { css } from '@emotion/core';

import Layout from 'src/components/layout';
import GraphCMSdefaultPage from './graphCMS/default-page';
// import GraphCMSgalleryPage from './graphCMS/gallery-page';

export const pageQuery = graphql`
	query StandardPageRender($id: ID!) {
		gcms {
			page(where: { id: $id }) {
				pageTitle
				pageSubtitle
				pageImage {
					url
					altText
					width
					height
				}
				pageTemplate
				featureContentKeys
				copy {
					html
				}
				seo {
					id
				}
			}
		}
	}
`;

const PageTemplate = ({
	data: {
		gcms: { page: pageNode },
	},
}) => {
	let { pageTemplate } = pageNode;
	// console.log('>> page_by_graphCMS TEMPLATE SORTING PAGE NODE:', pageNode);

	let pageRender;
	// TODO: INTEGRATE PAGE SEO: pageNode.seo

	// if () {}

	// if (pageTemplate === 'GALLERY') { // DEPRECATE
	// 	pageRender = <GraphCMSgalleryPage pageNode={pageNode} />;
	// }

	// LAST CASE
	if (
		pageTemplate === 'DEFAULT' ||
		PageTemplate === '' ||
		pageTemplate === undefined
	) {
		pageRender = <GraphCMSdefaultPage pageNode={pageNode} />;
	}
	// TODO: INTEGRATE OTHER PAGE TEMPLATE TYPES TBD

	return (
		<>
			<Layout>{pageRender}</Layout>
		</>
	);
};

export default PageTemplate;
