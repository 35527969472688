import React from 'react';

import ContentFeaturesRendered from '../../components/content_features';
import GCMSpageImageDisplay from '../../components/page/graphCMS/page-image-display';

const GCMSdefaultPage = ({ pageNode }) => {
	// console.log('>> GCMS DEFAULT PAGE WILL RENDER PAGE NODE:', pageNode);

	let pageImage = pageNode.pageImage ? (
		<GCMSpageImageDisplay imageNode={pageNode.pageImage} />
	) : null;

	return (
		<>
			<h1 className="page-main-heading">{pageNode.pageTitle}</h1>
			<h2 className="sub-heading">{pageNode.pageSubtitle}</h2>
			{pageImage}
			<ContentFeaturesRendered pageNode={pageNode} />
		</>
	);
};

export default GCMSdefaultPage;
