import React from 'react';
import { isEmpty } from '../../utils';
import HTMLparse from 'html-react-parser';

// Utilized components imported
// import SomeComp from './';

const ContentFeaturesRendered = ({ pageNode }) => {
	const { featureContentKeys, copy } = pageNode;
	// console.log(
	// 	'>> TEST RENDER CONTENT FEATURE SWITCH:',
	// 	featureContentKeys,
	// 	pageNode,
	// 	copy
	// );

	const contentKeys = [...featureContentKeys, 'DEFAULT_PAGE_CONTENT']; // Make a copy each time

	let renderedFeatureContent = contentKeys.map((featureKey, i) => {
		if (featureKey === 'RESUME_POSTER') {
			return <h1 key={i}>RESUME POSTER GOES HERE!!!</h1>;
		}

		if (featureKey === 'DEFAULT_PAGE_CONTENT' && copy) {
			// DEFAULT CONTENT RENDER
			let copy = HTMLparse(pageNode.copy.html);
			return (
				<div className="card-display" key={i}>
					{copy}
				</div>
			);
		}
	});

	return <>{renderedFeatureContent}</>;
};

export default ContentFeaturesRendered;
